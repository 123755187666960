<template>
  <ModelCreaterStyled>
    <div class="title">
      <button v-if="showPrevArrow" @click="goBack">
        <ArrowLeftIcon />
      </button>
      {{ title }}
    </div>
    <div class="main">
      <slot />
      <transition name="fade">
        <LoadingOverlayStyled v-if="loading">
          <TalpaLoaderWrapper />
        </LoadingOverlayStyled>
      </transition>
    </div>
    <div class="footer">
      <button :disabled="!canCreate" @click="$emit('create')"><CheckIcon /> {{ createLabel }}</button>
      <button :disabled="!canCancel" @click="$emit('cancel')"><XIcon /> cancel</button>
      <AlertTriangleIcon v-if="hasErrors" />
    </div>
  </ModelCreaterStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter, buttonReset } from '@styles/mixins'
import { CheckIcon, XIcon, AlertTriangleIcon } from 'vue-feather-icons'

import { TalpaLoaderWrapper } from '@common/components'
import { ArrowLeftIcon } from 'vue-feather-icons'
const ModelCreaterStyled = styled('div')`
  display: grid;
  margin: 2rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;
  grid-template-areas:
    'title title'
    'main main'
    'footer footer';

  > .title {
    ${flexCenter}
    justify-content: flex-start;
    grid-area: title;
    padding: 0.5rem;
    border-bottom: 2px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};
    button {
      ${buttonReset}
      ${flexCenter}
      margin-right: .5rem;
      padding: 0.2rem;
      &:hover {
        border-radius: 50%;
        background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
      }
    }
  }

  > .main {
    grid-area: main;
    position: relative;
    min-height: calc(10 * 41px);
  }

  > .footer {
    grid-area: footer;
    ${flexCenter}
    justify-content: space-between;
    padding: 0 0.5rem;
    min-height: 2rem;
    border-top: 2px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};
    button {
      ${buttonReset}
      ${flexCenter}
      margin-right: .5rem;
    }
  }
`

const LoadingOverlayStyled = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${p => chroma(p.theme.colors.black).alpha(0.8).css()};
`

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
    canCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPrevArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    createLabel: {
      type: String,
      required: false,
      default: 'create',
    },
  },
  components: {
    ModelCreaterStyled,
    LoadingOverlayStyled,
    CheckIcon,
    XIcon,
    AlertTriangleIcon,
    TalpaLoaderWrapper,
    ArrowLeftIcon,
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
  },
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>
