var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateUserStyled', [_c('InputField', {
    staticClass: "email",
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.email.$model,
            expression: "$v.email.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "someone@email.com"
          },
          domProps: {
            "value": _vm.$v.email.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.email, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('MailIcon')];
      },
      proxy: true
    }, _vm.$v.email.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.email.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.email.email ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must have a valid email.")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "username",
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.username.$model,
            expression: "$v.username.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Username"
          },
          domProps: {
            "value": _vm.$v.username.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.username, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('UserIcon')];
      },
      proxy: true
    }, _vm.$v.username.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.username.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.username.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must have at least " + _vm._s(_vm.$v.username.$params.minLength.min) + " letters.")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }