<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateUserStyled>
      <InputField class="email">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.email.$model" placeholder="someone@email.com" />
        </template>
        <template v-slot:label>
          <MailIcon />
        </template>
        <template v-slot:errors v-if="$v.email.$dirty">
          <div class="error" v-if="!$v.email.required">Field is required</div>
          <div class="error" v-if="!$v.email.email">Field must have a valid email.</div>
        </template>
      </InputField>
      <InputField class="username">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.username.$model" placeholder="Username" />
        </template>
        <template v-slot:label>
          <UserIcon />
        </template>
        <template v-slot:errors v-if="$v.username.$dirty">
          <div class="error" v-if="!$v.username.required">Field is required</div>
          <div class="error" v-if="!$v.username.minLength">Field must have at least {{ $v.username.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
    </CreateUserStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'

import { MailIcon, UserIcon } from 'vue-feather-icons'

import { required, minLength, email } from 'vuelidate/lib/validators'

import CREATE_USER_MUTATION from '#/graphql/users/createUser.gql'

const CreateUserStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
`

export default {
  components: {
    CreateUserStyled,
    ModelCreater,
    InputField,
    UserIcon,
    MailIcon,
    // Multiselect,
  },
  data() {
    return {
      email: '',
      username: '',
      password: '',
      errors: [],
      isLoading: false,
      isCreating: false,
    }
  },
  computed: {
    title() {
      return `Create User`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    username: {
      required,
      minLength: minLength(5),
    },
  },
  methods: {
    async create() {
      this.isCreating = true
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_USER_MUTATION,
          variables: {
            data: {
              email: this.email,
              username: this.username,
            },
          },
        })
        const id = get(res, 'data.createUser.id')
        if (id) {
          this.$emit('reload')
          this.$router.push({
            name: 'user',
            params: {
              id,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
      }
      this.isCreating = false
    },
  },
}
</script>
